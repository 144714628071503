import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return ( 
        <nav className="navbar">
            <h1>What Jobs Exist?</h1>
            <div className="links">
                <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')} >Home</NavLink>
                <NavLink to="/articles" className={({ isActive }) => (isActive ? 'active' : '')}>Articles</NavLink>
                <NavLink to="/gettingajob" className={({ isActive }) => (isActive ? 'active' : '')}>Getting a Job</NavLink>
            </div>
        </nav>
     );
}
 
export default Navbar;