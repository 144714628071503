import JobsList from './JobsList';
import useFetch from './useFetch';

const Home = () => {
    const { data: jobs, isPending, error } = useFetch('./jobs.json');

    return ( 
        <div className="home">
            {error  && <div>{error}</div>}
            {isPending && <div>Loading...</div>}
            {jobs && <JobsList jobs={jobs} title="List of Jobs:"/>}
            {jobs && <JobsList jobs={jobs.filter((job) => job.Degree === 'None')} title="List of Non-Degree Jobs:"/>}
        </div>
     );
}
 
export default Home;