import { useParams } from "react-router-dom";
import useFetch from "./useFetch";
import { useNavigate } from 'react-router-dom';

const Article = () => {
    const { id } = useParams()
    const { data: articles, isPending, error } = useFetch('../articles.json');
    console.log(articles);
    console.log(error);
    const article = articles ? articles[id-1] : false;
    const navigate = useNavigate();

    const handleClick = () => {
        /*
        fetch('delete api', {
            method: "DELETE"
        })
        .then(() => {
            navigate("/articles");
        })
        */
       console.log("Article Deleted");
       navigate("/articles");
    }

    return ( 
        <div className="article">
        {error  && <div>{error}</div>}
        {isPending && <div>Loading...</div>}
        {article && <div>
            <h2>{article.Title}</h2>
            <p>{article.Text}</p>
            <button onClick={handleClick}>Delete</button>
        </div>}
            
        </div>
     );
}
 
export default Article;