const JobsList = ({jobs, title}) => {
    return ( 
        <div className="jobsList">
        <h2>{title}</h2>
            {jobs.map((job) => (
                <div className="jobs" key={job.Name}>
                    <p>{job.Name}</p>
                </div>
            ))}
        </div>
    );
}
 
export default JobsList;