import ArticlesList from './ArticlesList';
import useFetch from './useFetch';

const Articles = () => {
    const { data: articles, isPending, error } = useFetch('./articles.json');

    return ( 
        <div className="home">
            {error  && <div>{error}</div>}
            {isPending && <div>Loading...</div>}
            {articles && <ArticlesList articles={articles}/>}
        </div>
    );
}
 
export default Articles;