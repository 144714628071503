import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreateArticle = () => {
    const [title, setTitle] = useState('sss');
    const [text, setText] = useState('');
    const [isPending, setIsPending] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const article = {title, text};

        setIsPending(true);

        /*
        fetch('', {
            method: 'POST',
            headers: {"Content-Type" : "application/json"},
            body: JSON.stringify(article)
        })
        .then(() => {
            console.log("new article added");
            setIsPending(false);
            //navigate(-1);
            navigate("/");
        })
        .catch(err => {
            console.log(err.message)
            //navigate(-1);
            navigate("/");
        });
        */
        console.log("Article Added");
        navigate("/articles");
    }

    return ( 
        <div className="createArticle">
            <h2>Add new article</h2>
            <form onSubmit={handleSubmit}>
                <label>Article title:</label>
                <input 
                    type="text"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <label>Article text:</label>
                <input 
                    type="textarea"
                    required
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                {isPending && <button>Adding Article...</button>}
                {!isPending && <button>Add Article</button>}
            </form>
        </div>
     );
}
 
export default CreateArticle;