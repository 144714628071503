import { useState, useEffect } from 'react';

const useFetch = (url) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const abortController = new AbortController();

        fetch(url, { signal: abortController.signal })
        .then(response => {
            if (!response.ok) {
                throw Error('Data fetch failed');
            }
            return response.json()
        })
        .then(data => {
            setError(null);
            setData(data);
            setIsPending(false);
        })
        .catch(err => {
            if (err.name === 'AbortError') {
                console.log('Fetch aborted');
            } else {
                setData(null);
                setIsPending(false);
                setError(err.message);
            }
        });

        return () => abortController.abort();
    },[url]);

    return {data, isPending, error};
}

export default useFetch;