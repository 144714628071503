import { Link } from "react-router-dom";

const ArticlesList = ({articles}) => {
    const linkval=""
    return ( 
        <div className="articlesList">
        <h2>Articles - <Link to='/createArticle'>Create New</Link></h2>
            {articles.map((article) => (
                <div className="articles" key={article.id}>
                    <Link to={'/articles/'+article.id}>
                        <h3>{article.Title} - {article.id}</h3>
                    </Link>
                </div>
            ))}
        </div>
    );
}
 
export default ArticlesList;